<template>
  <v-alert
    class="mb-0"
    :color="color"
    border="top"
    colored-border
    :type="type"
    elevation="2"
  >
    {{ message }}
  </v-alert>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    color: String,
    type: String,
    message: String,
  },
};
</script>
