<template>
  <v-data-table
    :headers="headers"
    :items="rowsItems"
    class="elevation-1 wettbewerb-table"
    :items-per-page="rowsItems.length"
    hide-default-footer
  >
    <template v-slot:header.min="{ header }">
      {{ header.text }}
      <br />
      <span class="average-symbol">&#x2300;</span>
      {{ calculateAverage(header) }}
    </template>
    <template v-slot:header.max="{ header }">
      {{ header.text }}
      <br />
      <span class="average-symbol">&#x2300;</span>
      {{ calculateAverage(header) }}
    </template>
    <template v-slot:header.mittelwert="{ header }">
      {{ header.text }}
      <br />
      <span class="average-symbol">&#x2300;</span>
      {{ calculateAverage(header) }}
    </template>
    <template v-slot:header.zins1="{ header }">
      {{ header.text }}
      <br />
      <span class="average-symbol">&#x2300;</span>
      {{ calculateAverage(header) }}
    </template>
    <template v-slot:header.zins2="{ header }">
      {{ header.text }}
      <br />
      <span class="average-symbol">&#x2300;</span>
      {{ calculateAverage(header) }}
    </template>
    <template v-slot:header.zins3="{ header }">
      {{ header.text }}
      <br />
      <span class="average-symbol">&#x2300;</span>
      {{ calculateAverage(header) }}
    </template>
    <template v-slot:header.zins4="{ header }">
      {{ header.text }}
      <br />
      <span class="average-symbol">&#x2300;</span>
      {{ calculateAverage(header) }}
    </template>
    <template v-slot:header.zins5="{ header }">
      {{ header.text }}
      <br />
      <span class="average-symbol">&#x2300;</span>
      {{ calculateAverage(header) }}
    </template>
    <template v-slot:[`item.datum`]="{ item }">
      <span>{{ $date(item.datum).format('DD.MM.YYYY') }}</span>
    </template>
    <template v-slot:[`item.min`]="{ item }">
      <span>{{ replaceDot(item.min) }}</span>
    </template>
    <template v-slot:[`item.max`]="{ item }">
      <span>{{ replaceDot(item.max) }}</span>
    </template>
    <template v-slot:[`item.mittelwert`]="{ item }">
      <span>{{ replaceDot(item.mittelwert) }}</span>
    </template>
    <template v-slot:[`item.zins1`]="{ item }">
      <span>{{ replaceDot(item.zins1) }}</span>
    </template>
    <template v-slot:[`item.zins2`]="{ item }">
      <span>{{ replaceDot(item.zins2) }}</span>
    </template>
    <template v-slot:[`item.zins3`]="{ item }">
      <span>{{ replaceDot(item.zins3) }}</span>
    </template>
    <template v-slot:[`item.zins4`]="{ item }">
      <span>{{ replaceDot(item.zins4) }}</span>
    </template>
    <template v-slot:[`item.zins5`]="{ item }">
      <span>{{ replaceDot(item.zins5) }}</span>
    </template>
  </v-data-table>
</template>

<script>
import { numberWithCommas } from '../../../utils/helpers';
import { mapGetters } from 'vuex';

export default {
  name: 'Table',
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
    }),
    rowsItems() {
      if (this.user?.isDemoUser) {
        return this.rows.filter((item) => {
          const iso = new Date(item?.datum);
          const dateFormat = this.$date(iso).format('DD-MM-YYYY');
          return dateFormat.includes('2020');
        });
      } else {
        return this.rows;
      }
    },
  },
  methods: {
    calculateAverage(header) {
      const values = this.rows.map((row) => row[header.value]);
      const average = values.reduce(
        (a, b) => Number(a || 0) + Number(b || 0),
        0
      );
      if (!isNaN(average)) {
        const result = average / values.length;
        const zerosAfterDot = -Math.floor(Math.log10(result) + 1);
        return result.toLocaleString('de-DE', {
          minimumFractionDigits: 2,
          maximumFractionDigits: zerosAfterDot >= 2 ? 3 : 2,
        });
      }
      return '0,00';
    },
    replaceDot(val) {
      return val ? numberWithCommas(val.toFixed(2)) : '0,00';
    },
  },
};
</script>

<style lang="scss">
.wettbewerb-table {
  [class~='average-symbol'] {
    font-size: 18px;
  }
}
</style>
