<template>
  <div>
    <v-menu
      color="#1194db"
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex">
          <v-text-field
            readonly
            :color="color"
            background-color="white"
            solo
            v-on="on"
            v-model="date"
            v-bind="attrs"
            class="input"
            @input="handleDateChange"
            dense
          >
            <template v-slot:append>
              <v-icon
                v-on="on"
                class="mdiCalendar"
                :class="{
                  'active-calendar': attrs['aria-expanded'] === 'true',
                }"
                >{{ 'mdi-calendar' }}
              </v-icon>
            </template>
          </v-text-field>
        </div>
      </template>
      <v-date-picker
        locale="de-DE"
        :color="color"
        ref="picker"
        v-model="date"
        :max="maxDate"
        :min="minDate"
        @change="save"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { BASE_BLACK_COLOR } from '../../../../../src/utils/constants';

export default {
  name: 'DatePicker',
  props: {
    spezialabonnenten: {
      type: Boolean,
      default: false,
    },
    storePath: String,
    maxDate: String,
    minDate: String,
    hypotheken: {
      type: Boolean,
      default: false,
    },
    festGeld: {
      type: Boolean,
    },
    storeNamespace: {
      type: String,
      default: 'param',
    },
  },
  computed: {
    ...mapGetters({
      forceUpdate: 'param/getForceUpdate',
    }),
    getDate() {
      const date = this.$store.getters[this.storeNamespace + '/getFieldValue'](
        this.storePath
      );
      if (date !== null) {
        return this.$date(new Date(date)).format('YYYY-MM-DD');
      }
      return null;
    },
  },
  data() {
    return {
      menu: false,
      date: null,
      color: BASE_BLACK_COLOR,
    };
  },
  methods: {
    handleDateChange(e) {
      const year = e.split('-');
      if (!this.spezialabonnenten) {
        if (
          e !== '' &&
          e !== null &&
          year[0].length === 4 &&
          year[0].startsWith(2, 0)
        ) {
          this.$store.commit(this.storeNamespace + '/setFieldValue', {
            path: this.storePath,
            value: new Date(`${e}`).toISOString(),
            hypotheken: this.hypotheken,
            festGeld: this.festGeld,
          });
        } else {
          this.$store.commit(this.storeNamespace + '/setFieldValue', {
            path: this.storePath,
            value: null,
            hypotheken: this.hypotheken,
            festGeld: this.festGeld,
          });
        }
      } else {
        if (
          e !== '' &&
          e !== null &&
          year[0].length === 4 &&
          year[0].startsWith(2, 0)
        ) {
          this.$store.commit(this.storeNamespace + '/setFieldValue', {
            path: this.storePath,
            value: new Date(`${e}`).toISOString(),
          });
        } else {
          this.$store.commit(this.storeNamespace + '/setFieldValue', {
            path: this.storePath,
            value: null,
          });
        }
      }
    },
    save(date) {
      this.$refs.menu.save(date);
      if (!this.spezialabonnenten) {
        if (this.date !== null) {
          this.$store.commit(this.storeNamespace + '/setFieldValue', {
            path: this.storePath,
            value: new Date(`${date}`).toISOString(),
            hypotheken: this.hypotheken,
            festGeld: this.festGeld,
          });
        }
      } else {
        this.$store.commit(this.storeNamespace + '/setFieldValue', {
          path: this.storePath,
          value: new Date(`${date}`).toISOString(),
        });
      }
    },
  },
  watch: {
    forceUpdate() {
      if (this.forceUpdate) {
        this.date = null;
      }
    },
  },
  mounted() {
    this.date = this.getDate;
  },
};
</script>

<style lang="scss" scoped>
input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
.active-calendar {
  color: $text !important;
}
.input {
  display: flex;
  justify-content: space-around;
}

.v-icon.v-icon:after {
  background-color: #fff !important;
}
.mdiCalendar {
  color: rgba(0, 0, 0, 0.54);
  &:focus:after {
    opacity: 0;
  }
}
</style>
