<template>
  <v-select
    :attach="true"
    v-model="modelSource"
    :items="chartColors"
    item-value="value"
    dense
    solo
    item-color="selected"
  >
    <template #selection="{ item }">
      <span class="no-wrap"
        ><v-icon :style="{ color: item.color }">{{ icon }}</v-icon>
        <span>{{ item.text }}</span></span
      >
    </template>
    <template v-slot:item="{ item }">
      <v-icon :style="{ color: item.color }">{{ icon }}</v-icon>
      <span>{{ item.text }}</span>
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'AnbieterChartColorSelectbox',
  props: {
    singleReport: {
      type: Boolean,
      default: false,
    },
    festGeld: Boolean,
    hypotheken: Boolean,
    storePath: String,
    storeNamespace: {
      type: String,
      default: 'param',
    },
    iconType: Number,
  },
  computed: {
    modelSource: {
      get() {
        return this.$store.getters[this.storeNamespace + '/getFieldValue'](
          this.storePath
        );
      },
      set(value) {
        this.$store.commit(this.storeNamespace + '/setFieldValue', {
          path: this.storePath,
          value: value,
          hypotheken: this.hypotheken,
          festGeld: this.festGeld,
        });
      },
    },
    anbieterMap() {
      return this.$store.state.anbieter.anbieterMap;
    },
    anbieterMapState() {
      return this.$store.state.anbieter.anbieterMapState;
    },
    chartColors() {
      return this.$store.state.chartColors.chartColors;
    },
    icon() {
      if (this.storePath.endsWith('1')) {
        return 'mdi-checkbox-blank';
      } else {
        return 'mdi-minus';
      }
    },
  },

  data() {
    return {};
  },

  watch: {
    anbieterMapState: function () {
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss">
.v-select__selections {
  & > input {
    display: none;
  }
}
.no-wrap {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
