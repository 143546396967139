export class FMHUtils {
  constructor(chart) {
    this.chart = chart;
  }
  get options() {
    return this.chart.options?.fmhFinanzberatung
      ? this.chart.options?.fmhFinanzberatung
      : false;
  }
}

const fmhFinanzberatungPlugin = {
  id: 'fmhFinanzberatung',
  afterDatasetDraw: (chart) => {
    const {
      ctx,
      chartArea: { left, bottom },
    } = chart;
    const fmhUtils = new FMHUtils(chart);
    const options = fmhUtils.options;
    const period = options?.period || '';
    ctx.font = '12px Recursive';
    ctx.fillStyle = '#181818';
    ctx.fillText(
      `Quelle: FMH-Finanzberatung      Zeitraum ${period}`,
      left + 20,
      bottom - 15
    );
    ctx.restore();
  },
};

export default fmhFinanzberatungPlugin;
