<template>
  <v-row class="columns">
    <v-col cols="2">
      <h6>Chart anzeigen mit</h6>
    </v-col>
    <v-col class="10">
      <v-row>
        <v-checkbox
          hide-details
          label="Maximum"
          v-model="chartMaxValue"
          :color="color"
        />
        <v-checkbox
          hide-details
          label="Mittelwert"
          v-model="chartAvgValue"
          :color="color"
        />
        <v-checkbox
          hide-details
          label="Minimum"
          v-model="chartMinValue"
          :color="color"
        />
        <v-checkbox
          hide-details
          v-if="isShow['zins1']"
          :label="formParam.bankname1"
          v-model="zins1"
          :color="color"
        />
        <v-checkbox
          hide-details
          v-if="isShow['zins2']"
          :label="formParam.bankname2"
          v-model="zins2"
          :color="color"
        />
        <v-checkbox
          hide-details
          v-if="isShow['zins3']"
          :label="formParam.bankname3"
          v-model="zins3"
          :color="color"
        />
        <v-checkbox
          hide-details
          v-if="isShow['zins4']"
          :label="formParam.bankname4"
          v-model="zins4"
          :color="color"
        />
        <v-checkbox
          hide-details
          v-if="isShow['zins5']"
          :label="formParam.bankname5"
          v-model="zins5"
          :color="color"
        />
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { checkedCheckBoxes } from '../../../../utils/helpers';
import { BASE_BLACK_COLOR } from '../../../../../../src/utils/constants';

export default {
  name: 'ChartHeader',
  props: {
    formParam: Object,
    data: Object,
    module: String,
    nameSpace: String,
  },
  computed: {
    isShow() {
      return checkedCheckBoxes(this.data);
    },
    chartMaxValue: {
      get() {
        return this.getFieldValue('chartMaxValue');
      },
      set(val) {
        this.updateValue('chartMaxValue', val);
      },
    },
    chartAvgValue: {
      get() {
        return this.getFieldValue('chartAvgValue');
      },
      set(val) {
        this.updateValue('chartAvgValue', val);
      },
    },
    chartMinValue: {
      get() {
        return this.getFieldValue('chartMinValue');
      },
      set(val) {
        this.updateValue('chartMinValue', val);
      },
    },
    zins1: {
      get() {
        return this.getFieldValue('zins1');
      },
      set(val) {
        this.updateValue('zins1', val);
      },
    },
    zins2: {
      get() {
        return this.getFieldValue('zins2');
      },
      set(val) {
        this.updateValue('zins2', val);
      },
    },
    zins3: {
      get() {
        return this.getFieldValue('zins3');
      },
      set(val) {
        this.updateValue('zins3', val);
      },
    },
    zins4: {
      get() {
        return this.getFieldValue('zins4');
      },
      set(val) {
        this.updateValue('zins4', val);
      },
    },
    zins5: {
      get() {
        return this.getFieldValue('zins5');
      },
      set(val) {
        this.updateValue('zins5', val);
      },
    },
  },
  methods: {
    getFieldValue(field) {
      const getterPath = `${this.module}/getField`;
      return this.$store.getters[getterPath](this.nameSpace)[field];
    },
    updateValue(field, value) {
      const mutationPath = `${this.module}/updateField`;
      this.$store.commit(mutationPath, {
        path: `${this.nameSpace}.${field}`,
        value,
      });
    },
  },
  data() {
    return {
      color: BASE_BLACK_COLOR,
    };
  },
};
</script>

<style lang="scss" scoped>
.columns {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 38px;
  & > div:nth-of-type(1) > h6 {
    font-size: 16px !important;
    font-weight: bolder;
    margin-right: 15px;
  }
  [class~='v-input'] {
    margin-right: 10px;
    margin-top: 0;
  }
  .label {
    color: rgba(0, 0, 0, 0.8) !important;
    z-index: 2 !important;
  }
}
</style>
