<template>
  <v-dialog v-model="model" width="500">
    <v-card>
      <v-card-title class="headline grey lighten-2"> {{ title }} </v-card-title>
      <v-card-text class="text_card base-normal-color">
        {{ text }}
      </v-card-text>
      <slot></slot>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="disabled"
          :loading="loading"
          :color="color"
          text
          @click="confirmAction"
        >
          {{ textActionButtons.confirm }}
        </v-btn>
        <v-btn
          :disabled="disabled"
          :loading="loading"
          :color="color"
          text
          @click="handleChangeDialog"
        >
          {{ textActionButtons.reject }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { BASE_BLACK_COLOR } from '@/utils/constants';

export default {
  name: 'ModalConfirm',
  props: {
    disabled: Boolean,
    loading: Boolean,
    textActionButtons: Object,
    text: String,
    title: String,
    dialog: Boolean,
    confirmAction: Function,
  },
  data() {
    return {
      color: BASE_BLACK_COLOR,
    };
  },
  computed: {
    model: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('change', value);
      },
    },
  },
  methods: {
    handleChangeDialog() {
      this.$emit('change', false);
    },
  },
};
</script>
