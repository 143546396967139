<template>
  <v-card
    class="add-bank-card"
    :class="{
      disabled: isShowAdditionalBanks.bank4 && isShowAdditionalBanks.bank5,
    }"
  >
    <div>
      <v-col class="d-flex justify-center pb-0 pt-0 plus">
        <v-icon size="100" :color="color">mdi-plus</v-icon>
      </v-col>
      <v-col class="d-flex justify-center pt-0 text-center">
        <span class="inter">Vergleichen Sie bis zu 5 Banken.</span></v-col
      >
    </div>
  </v-card>
</template>

<script>
import { BASE_BLACK_COLOR } from '../../../../../src/utils/constants';

export default {
  name: 'AddBankCard',
  props: {
    isShowAdditionalBanks: Object,
  },
  data() {
    return {
      color: BASE_BLACK_COLOR,
    };
  },
};
</script>

<style lang="scss" scoped>
.add-bank-card {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-top: 10px;
  padding: 5px;
  &:hover {
    cursor: pointer;
  }
}
.disabled {
  opacity: 0.4;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
}
</style>
