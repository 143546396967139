var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1 wettbewerb-table",attrs:{"headers":_vm.headers,"items":_vm.rowsItems,"items-per-page":_vm.rowsItems.length,"hide-default-footer":""},scopedSlots:_vm._u([{key:"header.min",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('br'),_c('span',{staticClass:"average-symbol"},[_vm._v("⌀")]),_vm._v(" "+_vm._s(_vm.calculateAverage(header))+" ")]}},{key:"header.max",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('br'),_c('span',{staticClass:"average-symbol"},[_vm._v("⌀")]),_vm._v(" "+_vm._s(_vm.calculateAverage(header))+" ")]}},{key:"header.mittelwert",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('br'),_c('span',{staticClass:"average-symbol"},[_vm._v("⌀")]),_vm._v(" "+_vm._s(_vm.calculateAverage(header))+" ")]}},{key:"header.zins1",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('br'),_c('span',{staticClass:"average-symbol"},[_vm._v("⌀")]),_vm._v(" "+_vm._s(_vm.calculateAverage(header))+" ")]}},{key:"header.zins2",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('br'),_c('span',{staticClass:"average-symbol"},[_vm._v("⌀")]),_vm._v(" "+_vm._s(_vm.calculateAverage(header))+" ")]}},{key:"header.zins3",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('br'),_c('span',{staticClass:"average-symbol"},[_vm._v("⌀")]),_vm._v(" "+_vm._s(_vm.calculateAverage(header))+" ")]}},{key:"header.zins4",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('br'),_c('span',{staticClass:"average-symbol"},[_vm._v("⌀")]),_vm._v(" "+_vm._s(_vm.calculateAverage(header))+" ")]}},{key:"header.zins5",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('br'),_c('span',{staticClass:"average-symbol"},[_vm._v("⌀")]),_vm._v(" "+_vm._s(_vm.calculateAverage(header))+" ")]}},{key:"item.datum",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$date(item.datum).format('DD.MM.YYYY')))])]}},{key:"item.min",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.replaceDot(item.min)))])]}},{key:"item.max",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.replaceDot(item.max)))])]}},{key:"item.mittelwert",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.replaceDot(item.mittelwert)))])]}},{key:"item.zins1",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.replaceDot(item.zins1)))])]}},{key:"item.zins2",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.replaceDot(item.zins2)))])]}},{key:"item.zins3",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.replaceDot(item.zins3)))])]}},{key:"item.zins4",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.replaceDot(item.zins4)))])]}},{key:"item.zins5",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.replaceDot(item.zins5)))])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }