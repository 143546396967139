<template>
  <div>
    <v-combobox
      clearable
      v-model="modelSource"
      dense
      solo
      :items="options"
      label="Bitte wählen ..."
      clear-icon="mdi-close-circle"
      :color="color"
      item-color="selected"
    >
    </v-combobox>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { COLOR } from '../../../../../src/utils/constants';

export default {
  name: 'AnbieterSelectbox',
  props: {
    singleReport: {
      type: Boolean,
      default: false,
    },
    hypotheken: {
      type: Boolean,
      default: false,
    },
    festGeld: {
      type: Boolean,
    },
    storePath: String,
    storeNamespace: {
      type: String,
      default: 'param',
    },
  },
  computed: {
    ...mapGetters({ getAnbieter: 'anbieter/getAnbieter' }),
    modelSource: {
      get() {
        return this.$store.getters[this.storeNamespace + '/getFieldValue'](
          this.storePath
        );
      },
      set(item) {
        this.$store.commit(this.storeNamespace + '/setFieldValue', {
          path: this.storePath,
          value: item?.value || '',
          hypotheken: this.hypotheken,
          festGeld: this.festGeld,
        });
      },
    },
    options() {
      const result = [
        {
          text: 'Bundesweite Anbieter'.toUpperCase(),
          value: '0',
          disabled: true,
        },
      ];
      if (Array.isArray(this.getAnbieter[1])) {
        this.getAnbieter[1].forEach((item) => {
          result.push({ text: item, value: item, disabled: false });
        });
      }
      result.push({
        text: 'Regionale Anbieter'.toUpperCase(),
        value: '1',
        disabled: true,
      });
      if (Array.isArray(this.getAnbieter[2])) {
        this.getAnbieter[2].forEach((item) => {
          result.push({ text: item, value: item });
        });
      }
      result.push({
        text: 'Vermittler banken'.toUpperCase(),
        value: '2',
        disabled: true,
      });
      if (Array.isArray(this.getAnbieter[4])) {
        this.getAnbieter[4].forEach((item) => {
          result.push({ text: item, value: item });
        });
      }
      return result;
    },
  },
  data() {
    return {
      value: null,
      color: COLOR,
    };
  },
};
</script>

<style lang="scss">
.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
.v-select__selections {
  & > input {
    display: none;
  }
}
</style>
