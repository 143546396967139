<template>
  <v-row class="dpf-header">
    <img src="/assets/img/fmh-logo.png" alt="Logo" :width="200" :height="30" />
    <v-chip small class="selected-tag inter">Erstellt am: {{ created }}</v-chip>
  </v-row>
</template>
<script>
export default {
  name: 'PdfHeader',
  computed: {
    created() {
      return this.$date(new Date()).format('DD.MM.YYYY');
    },
  },
};
</script>

<style lang="scss">
.dpf-header {
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 0 0;
  margin-bottom: 50px;
}
.selected-tag {
  background-color: $fmh-selected !important;
  opacity: 0.8;
  color: white !important;
  padding-bottom: 3px;
}
</style>
